import { S25Util } from "../../util/s25-util";
import { SheetEntry, Stox, WorkBook, XLSX } from "./types/sheet.js.types";

export class SpreadSheetService {
    static scriptsPromise: Promise<void> = null;

    public static load() {
        SpreadSheetService.scriptsPromise =
            SpreadSheetService.scriptsPromise ||
            S25Util.all([]).then(async () => {
                await S25Util.loadScript(
                    "xlsx-spreadsheet-css",
                    "https://unpkg.com/x-data-spreadsheet@1.1.9/dist/xspreadsheet.css",
                );
                await S25Util.loadScript(
                    "xlsx-spreadsheet-js",
                    "https://unpkg.com/x-data-spreadsheet@1.1.9/dist/xspreadsheet.js",
                );
                await S25Util.loadScript(
                    "xlsx-shim-js",
                    "https://cdn.sheetjs.com/xlsx-latest/package/dist/shim.min.js",
                );
                await S25Util.loadScript(
                    "xlsx-full-js",
                    "https://cdn.sheetjs.com/xlsx-latest/package/dist/xlsx.full.min.js",
                );
                await S25Util.loadScript(
                    "xlsx-xlsxspread-js",
                    "https://cdn.sheetjs.com/xspreadsheet/xlsxspread.min.js",
                );
                return;
            });
        return SpreadSheetService.scriptsPromise;
    }

    public static async XLSX(): Promise<XLSX> {
        await SpreadSheetService.load();
        return window.XLSX;
    }

    public static async stox(workbook: WorkBook): Promise<Stox> {
        await SpreadSheetService.load();
        return window.stox(workbook);
    }

    public static async toSpreadsheet(sheetData: SheetEntry[], sheetName: string = "Sheet1") {
        let xlsx = await SpreadSheetService.XLSX();
        const ws = xlsx.utils.json_to_sheet(sheetData);
        const wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, sheetName);
        return wb;
    }

    public static async downloadExcel(sheetData: SheetEntry[], filename: string, sheetName: string = "Sheet1") {
        const wb = await SpreadSheetService.toSpreadsheet(sheetData, sheetName);
        let xlsx = await SpreadSheetService.XLSX();
        xlsx.writeFile(wb, filename);
    }
}
