import { TypeManagerDecorator } from "../../main/type.map.service";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { S25File } from "../s25-file-upload/s25.file.upload.component";
import { SpreadSheetService } from "./spreadsheet.service";
import Spreadsheet from "./types/x-spreadsheet.types";
import { RowData, Stox, WorkBook, XLSX } from "./types/sheet.js.types";
import { ScrollLockService } from "../../services/scroll.lock.service";

@TypeManagerDecorator("s25-ng-spreadsheet")
@Component({
    selector: "s25-ng-spreadsheet",
    template: `
        <s25-ng-file-upload
            [onAdd]="handleFileUpload"
            [readAsArrayBuffer]="true"
            [showFiles]="false"
        ></s25-ng-file-upload>
        @if (onComplete) {
            <s25-ng-button
                [buttonClass]="'ngBlock ngFloatRight c-margin-top--single c-margin-bottom--single'"
                [type]="'primary'"
                [onClick]="handleComplete"
                >{{ completeText }}</s25-ng-button
            >
        }
        <div
            [hidden]="!uploaded"
            [style.width]="'fit-content'"
            class="ngHeight100 c-margin-top--single"
            #spreadsheetEl
        ></div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpreadsheetComponent implements OnInit, OnDestroy {
    @ViewChild("spreadsheetEl") spreadsheetEl: ElementRef<HTMLDivElement>;

    @Input() onUpload?: (rows: RowData[]) => void;
    @Input() onComplete?: (rows: RowData[]) => Promise<unknown>;
    @Input() completeText?: string = "Done";

    spreadsheet: Spreadsheet;
    init = false;
    uploaded = false;
    spreadsheetCreated = false;
    XLSX: XLSX;
    scrollLocksHandled = false;

    constructor(private cd: ChangeDetectorRef) {}

    handleComplete = () => {
        return (this.onComplete && this.onComplete(this.getSheetData())) || Promise.resolve();
    };

    handleFileUpload = async (file: S25File) => {
        this.uploaded = true;
        this.cd.detectChanges();
        await SpreadSheetService.load();
        if (!this.spreadsheetCreated) {
            this.spreadsheetCreated = true;
            this.spreadsheet = window.x_spreadsheet(this.spreadsheetEl.nativeElement, {
                mode: "edit",
                showToolbar: false,
                showGrid: true,
                showContextmenu: true,
                showBottomBar: false,
                view: {
                    height: () => 500,
                    width: () => 800,
                },
            });
            this.handleScrollLock();
        }
        await this.processWorkbook(this.XLSX.read(file.data));
    };

    processWorkbook = async (workbook: WorkBook) => {
        let xsprData: Stox = await SpreadSheetService.stox(workbook);
        this.spreadsheet.loadData(xsprData);
        this.onUpload && this.onUpload(this.getSheetData());
        this.cd.detectChanges();
    };

    getSheetData = (): RowData[] => {
        if (!this.spreadsheet) {
            return [];
        }
        let rows: RowData[] = [];
        let data = this.spreadsheet.getData();
        if (!data || !data.length || !data[0]) {
            return rows;
        }
        let sheet = data[0];
        if (sheet.rows) {
            for (let rowNum in sheet.rows) {
                let row = sheet.rows[rowNum];
                let rowData: RowData = { cells: [] };
                let cols = 0;
                for (let colNum in row.cells) {
                    let cell = row.cells[colNum];
                    cols++;
                    rowData.cells.push({ text: S25Util.toStr(cell?.text) });
                }
                if (cols <= 0) {
                    break;
                }
                rows.push(rowData);
            }
        }
        return rows;
    };

    handleScrollLock = () => {
        if (this.scrollLocksHandled) {
            return;
        }
        this.scrollLocksHandled = true;

        this.spreadsheetEl.nativeElement.addEventListener("mouseover", () => {
            ScrollLockService.lockBodyScroll();
        });

        this.spreadsheetEl.nativeElement.addEventListener("mouseout", (evt) => {
            if (!this.isEventOverlappingElement(evt, this.spreadsheetEl.nativeElement)) {
                ScrollLockService.unlockBodyScroll();
            }
        });

        document.addEventListener("wheel", this.wheelListener, { passive: false });
    };

    wheelListener = (event: WheelEvent) => {
        if ((event.target as HTMLElement)?.classList?.contains("x-spreadsheet-overlayer")) {
            if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
                event.preventDefault();

                const currentScroll = this.spreadsheet.sheet.horizontalScrollbar.scroll();
                let newLeftPosition = currentScroll.left + event.deltaX;

                this.spreadsheet.sheet.horizontalScrollbar.moveFn(newLeftPosition);
                this.spreadsheet.sheet.horizontalScrollbar.move({ left: newLeftPosition });
            }
        }
    };

    isEventOverlappingElement = (event: MouseEvent, element: HTMLElement) => {
        const rect = element.getBoundingClientRect();
        return (
            event.clientX >= rect.left &&
            event.clientX <= rect.right &&
            event.clientY >= rect.top &&
            event.clientY <= rect.bottom
        );
    };

    async ngOnInit() {
        this.XLSX = await SpreadSheetService.XLSX();
        this.init = true;
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        ScrollLockService.unlockBodyScroll();
        document.removeEventListener("wheel", this.wheelListener);
    }
}
